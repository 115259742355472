import { module } from 'modujs';
import "select2/dist/css/select2.min.css";
import "select2/dist/js/select2.min.js";

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          focusin: {
            input: 'active',
          },
          focusout: {
            input: 'desactive',
          },
          change: {
            file: 'initfile',
          }
        }
    }

    init() {
      let select2 = this.$("select2");
      let placeholder = "Choisir";
      if (select2) {
        select2.forEach((element) => {
          if (element.getAttribute("data-placeholder")) {
            placeholder = element.getAttribute("data-placeholder");
          }
          $(element).select2({
            placeholder: placeholder,
            allowClear: true,
          });
          // $('#'+element.getAttribute('id')).on('change.select2', function() {
          //     $this.call('refresh', false, 'Filtres');
          // });
          $("#" + element.getAttribute("id")).on("select2:open", function () {
            $(".select2-results__options").attr("data-lenis-prevent", "true");
          });
          if(element.getAttribute("data-change") == "true") {
            $("#" + element.getAttribute("id")).on("select2:select", function () {
              select2.forEach((other) => {
                if(element.getAttribute("id") != other.getAttribute("id")) {
                  $("#" + other.getAttribute("id")).val("").trigger("change");
                }
              });
              element.closest('form').submit();
            });
          }
          $("#" + element.getAttribute("id")).on("select2:clear", function () {
            element.closest('form').submit();
          });
        });
      }
    }

    active(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      item.classList.add('is-active');
    }

    desactive(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      if(target.value == "") {
        item.classList.remove('is-active');
      }
    }

    initfile(e) {
      const target = e.currentTarget;
      var $input = $( target ),
      $label	 = $input.next( 'label' ),
      labelVal = $label.html();

      var fileName = '';

      if( target.files && target.files.length > 1 )
        fileName = ( target.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', target.files.length );
      else if( target.value )
        fileName = target.value.split( '\\' ).pop();

      if( fileName )
        $label.find( '.title' ).html( fileName );
      else
        $label.html( labelVal );

    }
}
